<div class="container">
    <div class="d-flex justify-content-center" *ngIf="loading">
        <app-loading></app-loading>
    </div>
    <div class="action" *ngIf="!loading && ID">
        <button class="btn btn-info" (click)="displayInitialPaymentConfirmationModal()" *ngIf="job.status == 'Pending'">MAKE INITIAL PAYMENT</button>
        <button class="btn btn-info" (click)="displayPaymentConfirmationModal()" *ngIf="job.milestones.length == 0 && job.amountPaid < job.budget && job.status == 'In Progress'">PAY REMAINING AMOUNT</button>
        <button class="btn btn-info" (click)="displayApproveWorkModal()" *ngIf="job.status == 'In Progress' && job.amountPaid == job.budget && !hasPendingSubmissions()">MARK AS COMPLETE</button>
    </div>
    <div class="card top mb-3" *ngIf="!loading && ID">
        <h2 class="card-title">{{job.title}}</h2>
        <p class="card-subtitle mb-2 text-body-secondary">{{job.description}}</p>
        <div class="row mb-3">
            <div class="col-md-3">
                <p class="card-text"><strong>Posted: </strong>{{job.created_on | date: 'mediumDate'}}</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Budget: </strong>{{job.budget}} USD</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Deadline: </strong>{{job.deadline | date: 'mediumDate'}}</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Status: </strong>{{job.status}}</p>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-3">
                <p class="card-text"><strong>Applicants: </strong>{{job.applicants.length}}</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Amount Paid: </strong>{{(job.amountPaid).toFixed(2)}} USD</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Balance: </strong>{{(job.balance).toFixed(2)}} USD</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Percent Paid By client: </strong>{{(job.amountPaid/job.budget)*100}}%</p>
            </div>
        </div>
        <div class="row mb-3" *ngIf="job.status == 'Pending'">
            <p><strong>NB: </strong>You are required to pay <strong>25%</strong> of the budget for the freelancer to begin working.</p>
        </div>
        <p class="card-text mb-5"><strong>Required Skills: </strong>{{job.skills}}</p>
        <h3 *ngIf="job.milestones.length > 0">Milestones</h3>
        <div *ngFor="let milestone of job.milestones" class="mb-3">
            <div class="row mb-3">
                <div class="col-md-4">
                    <p class="card-text"><strong>Deadline: </strong>{{milestone.milestoneDeadline | date: 'mediumDate'}}</p>
                </div>
                <div class="col-md-4">
                    <button class="btn btn-info" *ngIf="milestone.status != 'Paid' && job.status == 'In Progress'" (click)="displayPaymentConfirmationModal(milestone.milestoneId)">MAKE PAYMENT</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="card-text"><strong>Description: </strong>{{milestone.milestoneDescription }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="middle mb-3" *ngIf="!loading && ID">
        <h3>Selected Freelancer</h3>
        <div class="row" *ngIf="!job.freelancer">
            <p>No Freelancer has been selected yet. The selected freelancer will be displayed here.</p>
        </div>

        <div class="row" *ngIf="job.freelancer">
            <div class="col-md-4 wrapper mb-5">
                <div class="card" style="width: 18rem;">
                    <div class="card-body">
                        <div class="avatar">
                            <div class="avatar-preview">
                                <div class="image-preview"
                                    [style.backgroundImage]="'url('+ this.job.freelancer.profile_image +')'">
                                </div>
                            </div>
                        </div>
                        <h5 class="card-title">{{job.freelancer.firstname}} {{job.freelancer.lastname}}</h5>
                        <p class="card-text text-center">{{job.freelancer.title}}</p>
                        <p class="card-text text-center"><span class="badge rounded-pill text-bg-light"
                            *ngFor="let skill of job.freelancer.skills">{{skill}}</span>
                        </p>
                        <p class="card-text text-center applied">Applied On: {{job.freelancer.applied_on | date: 'mediumDate'}}</p>
                        <p class="card-text text-center applied">Approved On: {{job.freelancer.approved_on | date: 'mediumDate'}}</p>
                        <a class="btn btn-info" [href]="'/posted-jobs/' + job._id + '/applicants/' + job.freelancer._id">VIEW PROFILE</a>
                        <button class="btn btn-info" (click)="sendMessage(job.freelancer)">SEND MESSAGE</button>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <p class="text-center" *ngIf="job.submissions.length == 0"><strong>Work that has been submitted will be displayed here.</strong></p>
                <div class="submissions" *ngIf="job.submissions.length > 0">
                    <h3>Submissions</h3>
                    <div class="card mb-2" *ngFor="let submission of job.submissions | paginate : submissionsPagingConfig; let i = index">
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-md-3">
                                    <p><strong>Submitted On: </strong>{{submission.submitted_on | date: 'mediumDate'}}</p>
                                </div>
                                <div class="col-md-3">
                                    <p><a href={{submission.link}} target="_blank">Link to Submitted Work</a></p>
                                </div>
                                <div class="col-md-3">
                                    <p><strong>Status: </strong>{{submission.status | titlecase}}</p>
                                </div>
                                <div class="col-md-3">
                                    <div class="file mb-2" *ngIf="submission.file">
                                        <button class="btn btn-info" (click)="downloadFile(submission.file)" [disabled]="downloadingFileMap[submission.file]">
                                            DOWNLOAD FILE
                                            <span *ngIf="downloadingFileMap[submission.file]" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <p><strong>Comment: </strong>{{ submission.comment }}</p>
                                </div>
                                <div class="col-md-6" *ngIf="submission.feedback">
                                    <p><strong>Feedback: </strong>{{ submission.feedback }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-2" *ngIf="submission.status == 'pending'">
                                    <button class="btn btn-info" (click)="displayApproveSubmissionModal(submission.submission_id)">
                                        APPROVE
                                    </button>
                                </div>
                                <div class="col-md-2" *ngIf="submission.status == 'pending'">
                                    <button class="btn btn-info" (click)="displayRejectSubmissionModal(submission.submission_id)">
                                        REJECT
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onSubmissionsDataChange($event)">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row bottom">
        <div class="applicants" *ngIf="!loading && ID">
            <h3>Applicants</h3>
            <div class="no-applicants" *ngIf="job.applicants.length == 0">
                <p>There are no applicants yet.</p>
            </div>
            <div class="row">
                <div class="wrapper mb-5" *ngFor="let applicant of job.applicants">
                    <div class="card" style="width: 18rem;">
                        <div class="card-body">
                            <div class="avatar">
                                <div class="avatar-preview">
                                    <div class="image-preview"
                                        [style.backgroundImage]="'url('+ applicant.profile_image +')'"></div>
                                </div>
                            </div>
                            <h5 class="card-title">{{applicant.firstname}} {{applicant.lastname}}</h5>
                            <p class="card-text text-center">{{applicant.title}}</p>
                            <p class="card-text text-center"><span class="badge rounded-pill text-bg-light"
                                *ngFor="let skill of applicant.skills ">{{skill}}</span>
                            </p>
                            <p class="card-text text-center applied">Applied On: {{applicant.applied_on | date: 'mediumDate'}}</p>
                            <a class="btn btn-info" [href]="'/posted-jobs/' + job._id + '/applicants/' + applicant._id">VIEW PROFILE</a>
                            <button class="btn btn-info" (click)="sendMessage(applicant)">SEND MESSAGE</button>
                            <button class="btn btn-info" (click)="approveApplicant(job._id, applicant._id)" [disabled]="approveLoading" *ngIf="!job.freelancer">
                                SELECT APPLICANT
                                <span *ngIf="approveLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- APPROVE WORK MODAL -->
<ng-template #approveWorkModal let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">JOB COMPLETED CONFIRMATION</h5>
    </div>
    <div class="modal-body">
        <div>
            <p>If you choose to mark this job as complete, it's status will be changed to <strong>Completed</strong>.</p>
            <p>You will also be allowed to review the freelancer when you view their profile. You will only be allowed to review them once.</p>
            <p>Only mark this job as complete when you are fully satisfied with the work done by the freelancer. </p>
            <p>Are you sure you want to mark this job as complete?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dark" (click)="close()">NO</button>
        <button class="btn btn-dark" (click)="approveWork()" [disabled]="approveLoading">MARK AS COMPLETE
            <span *ngIf="approveLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
    </div>
</ng-template>

<!-- INITIAL PAYMENT CONFIRMATION MODAL -->
<ng-template #initialPaymentConfirmationModal let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">CONFIRM PAYMENT</h5>
    </div>
    <div class="modal-body">
        <div>
            <p><strong>NB: </strong>You are required to pay <strong>25%</strong> of the budget for the freelancer to begin working.</p>
            <p>If you are ready to make the payment, you will be redirected to the payment page.</p>
            <p>Amount: <strong>{{(job.budget * 0.25).toFixed(2)}} USD.</strong></p>
            <p>Would you like to make the payment now?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dark" (click)="close()">NOT NOW</button>
        <button class="btn btn-dark" (click)="checkout(job.budget * 0.25)" [disabled]="paymentLoading">MAKE PAYMENT NOW
            <span *ngIf="paymentLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
    </div>
</ng-template>

<!-- APPROVE SUBMISSION MODAL -->
<ng-template #approveSubmissionModal let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">APPROVE SUBMISSION</h5>
    </div>
    <div class="modal-body">
        <form [formGroup]="feedbackForm">
            <div class="form-group mb-3">
                <label for="feedback">Feedback</label>
                <textarea class="form-control" formControlName="feedback" rows="4" placeholder="Feedback..." 
                    [ngClass]="{ 'is-invalid': submitted && f['feedback'].errors}">
                </textarea>
                <div *ngIf="submitted && f['feedback'].errors" class="invalid-feedback">
                    <div *ngIf="f['feedback']['errors']required">Feedback is required</div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dark" (click)="close()">CLOSE</button>
        <button class="btn btn-dark" (click)="approveSubmission()" [disabled]="approveSubmissionLoading">APPROVE SUBMISSION
            <span *ngIf="approveSubmissionLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
    </div>
</ng-template>

<!-- REJECT SUBMISSION MODAL -->
<ng-template #rejectSubmissionModal let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">REJECT SUBMISSION</h5>
    </div>
    <div class="modal-body">
        <form [formGroup]="feedbackForm">
            <div class="form-group mb-3">
                <label for="feedback">Feedback</label>
                <textarea class="form-control" formControlName="feedback" rows="4" placeholder="Feedback..." 
                    [ngClass]="{ 'is-invalid': submitted && f['feedback'].errors}">
                </textarea>
                <div *ngIf="submitted && f['feedback'].errors" class="invalid-feedback">
                    <div *ngIf="f['feedback']['errors']required">Feedback is required</div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dark" (click)="close()">CLOSE</button>
        <button class="btn btn-dark" (click)="rejectSubmission()" [disabled]="rejectSubmissionLoading">REJECT SUBMISSION
            <span *ngIf="rejectSubmissionLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
    </div>
</ng-template>

<!-- PAYMENT CONFIRMATION MODAL -->
<ng-template #paymentConfirmationModal let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">CONFIRM PAYMENT</h5>
    </div>
    <div class="modal-body">
        <div>
            <p *ngIf="job.milestones.length > 0">NB: <strong>75%</strong> of the budget of the job is distributed evenly among the milestones.</p>
            <p>If you are ready to make the payment, you will be redirected to the payment page.</p>
            <p *ngIf="milestoneId">Once you make payment for this milestone, it's status will be set to <strong>PAID</strong></p>
            <p *ngIf="job.milestones.length > 0">Number of milestones not paid: {{job.milestones.length - job.milestonesPaid}}</p>
            <p *ngIf="job.milestones.length > 0">Balance: <strong>{{(job.balance).toFixed(2)}} USD</strong></p>
            <p>Amount: <strong>{{ (job.balance / (job.milestones.length - job.milestonesPaid || 1)).toFixed(2) }} USD.</strong></p>
            <p>Are you sure you want to make the payment?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dark" (click)="close()">NO</button>
        <button class="btn btn-dark" (click)="checkout(job.balance / (job.milestones.length - job.milestonesPaid || 1))" [disabled]="paymentLoading">MAKE PAYMENT
            <span *ngIf="paymentLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
    </div>
</ng-template>