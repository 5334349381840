<div class="container">
    <button type="button" class="btn btn-info mb-3" (click)="openPostJobModal(false)">POST JOB</button>
    <div class="d-flex justify-content-center" *ngIf="isLoading">
        <app-loading></app-loading>
    </div>
    <div *ngIf="!jobsAvailable && !isLoading">
        <h2>UH OH!</h2>
        <p> You have not posted any Job yet. Posted jobs will be displayed here.</p>
    </div>
    <div *ngIf="jobsAvailable && !isLoading">
        <h3>Posted Jobs</h3>
        <div class="card" *ngFor="let job of jobs | paginate : jobsPagingConfig; let i = index">
            <div class="card-body">
                <h5 class="card-title"><a [href]="'/posted-jobs/' + job._id">{{job.title}}</a>
                    <fa-icon class="okay" [icon]="paidIcon" *ngIf="job.balance == 0"></fa-icon>
                </h5>
                <p class="card-subtitle mb-3 text-body-secondary">{{job.description}}</p>
                <div class="row mb-3">
                    <div class="col-md-2">
                        <p class="card-text"><strong>Posted: </strong>{{job.created_on | date: 'mediumDate'}}</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-text"><strong>Budget: </strong>{{job.budget}} USD</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-text"><strong>Applicants: </strong>{{job.applicants.length}}</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-text"><strong>Status: </strong>{{job.status}}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="card-text"><strong>Deadline: </strong>{{job.deadline | date: 'mediumDate'}}</p>
                    </div>
                </div>
                <p class="card-text mb-3"><strong>Required Skills: </strong>{{job.skills}}</p>
                <div class="row">
                    <p>
                        <fa-icon class="edit" [icon]="editIcon" (click)="openPostJobModal(true, job)" *ngIf="job.status == 'Open' || job.status == 'In Progress'"></fa-icon>
                        <fa-icon class="delete" [icon]="deleteIcon" *ngIf="job.status == 'Open'" (click)="openCancelJobModal(job)"></fa-icon>
                    </p>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center paginator">
            <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onjobsDataChange($event)">
            </pagination-controls>
        </div>
    </div>

    <!-- POST AND UPDATE JOB MODAL -->
    <ng-template #postJobModal let-close="close">
        <div class="modal-header">
            <h5 class="modal-title" *ngIf="!updateJob">Post Job</h5>
            <h5 class="modal-title" *ngIf="updateJob">Update Job</h5>
        </div>
        <div class="modal-body">
            <form [formGroup]="postJobForm">
                <div class="form-group mb-3">
                    <label for="title"><strong>Title</strong></label>
                    <input type="text" class="form-control" formControlName="title"
                        [ngClass]="{ 'is-invalid': submitted && f['title'].errors}">
                    <div *ngIf="submitted && f['title'].errors" class="invalid-feedback">
                        <div *ngIf="f['title']['errors']required">Title is required</div>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="description"><strong>Description</strong></label>
                    <textarea class="form-control" formControlName="description" rows="5"
                        [ngClass]="{ 'is-invalid': submitted && f['description'].errors}">
                    </textarea>
                    <div *ngIf="submitted && f['description'].errors" class="invalid-feedback">
                        <div *ngIf="f['description']['errors']required">Description is required</div>
                    </div>
                </div>
                <div *ngIf="updateJob" class="mb-3">
                    <h6><strong>Current Categories:</strong></h6>
                    <span class="badge rounded-pill text-bg-light" *ngFor="let category of previousCategories ">{{category}}</span>
                </div>
                <div class="mb-3">
                    <h6><strong>Select categories</strong></h6>
                    <p *ngIf="updateJob">The categories you select will overwrite the current categories</p>
                    <div class="row">
                        <div *ngFor="let service of services" class="col-md-4">
                            <mat-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="toggleCategorySelection(service)">
                                {{ service.title }}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="info" *ngIf="noCategorySelected">
                        <p>Select at least one category.</p>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="skills"><strong>Additional / Specific Skills Required</strong></label>
                    <input type="text" class="form-control" formControlName="skills"
                        [ngClass]="{ 'is-invalid': submitted && f['skills'].errors}">
                    <div *ngIf="submitted && f['skills'].errors" class="invalid-feedback">
                        <div *ngIf="f['skills']['errors']required">Skills are required</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="budget"><strong>Budget in USD</strong></label>
                        <input type="number" class="form-control" formControlName="budget"
                            [ngClass]="{ 'is-invalid': submitted && f['budget'].errors}" placeholder="Minimum - 10 USD">
                        <div *ngIf="submitted && f['budget'].errors" class="invalid-feedback">
                            <div *ngIf="f['budget']['errors']required">Budget is required</div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <mat-form-field class="full-width mb-3">
                            <input matInput readonly [matDatepicker]="deadline" placeholder="Deadline"
                                formControlName="deadline" [min]="today" [ngClass]="{ 'is-invalid': submitted && f['deadline'].errors}" required>
                            <div *ngIf="submitted && f['deadline'].errors" class="invalid-feedback">
                                <div *ngIf="f['deadline']['errors']required">Deadline is required</div>
                            </div>
                            <mat-datepicker-toggle matSuffix [for]="deadline"></mat-datepicker-toggle>
                            <mat-datepicker #deadline></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <div class="milestones">
                    <p>
                        Milestones are a way to break your project into smaller deliverables. As each milestone is completed, you will pay your freelancer a portion of the total project budget.
                        3 to 5 milestones is a good place to start. Think of them as a way to keep you and your freelancer on track.
                    </p>
                    <button type="button" class="btn btn-info mb-3" (click)="addMilestone()">ADD MILESTONE</button>
                    <div formArrayName="milestones">
                        <div *ngFor="let milestone of milestones.controls; let i = index" [formGroupName]="i">
                          <div class="row">
                            <div class="col-md-12 mb-3">
                              <label for="milestoneDeadline"><strong>Deadline</strong></label>
                              <input type="date" class="form-control" formControlName="milestoneDeadline"
                                     [ngClass]="{ 'is-invalid': isMilestoneControlInvalid(i, 'milestoneDeadline') }">
                              <div *ngIf="isMilestoneControlInvalid(i, 'milestoneDeadline')" class="invalid-feedback">
                                Deadline is required
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12 mb-3">
                              <label for="milestoneDescription"><strong>Description</strong></label>
                              <textarea class="form-control" formControlName="milestoneDescription" rows="3"
                                        [ngClass]="{ 'is-invalid': isMilestoneControlInvalid(i, 'milestoneDescription') }"></textarea>
                              <div *ngIf="isMilestoneControlInvalid(i, 'milestoneDescription')" class="invalid-feedback">
                                Description is required
                              </div>
                            </div>
                          </div>
                          <button type="button" class="btn btn-danger mt-2" (click)="deleteMilestone(i)">DELETE MILESTONE</button>
                        </div>
                    </div>
                </div>
                <hr>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dark" (click)="cancelModal()">CLOSE</button>
            <button class="btn btn-dark" (click)="postJob()" [disabled]="isLoading2" *ngIf="!updateJob">CREATE JOB
                <span *ngIf="isLoading2" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
            </button>
            <button class="btn btn-dark" (click)="editJob()" [disabled]="isLoading2" *ngIf="updateJob">UPDATE JOB
                <span *ngIf="isLoading2" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
            </button>
        </div>
    </ng-template>

    <!-- CANCEL JOB CONFIRMATION MODAL -->
    <ng-template #cancelJobModal let-close="close">
        <div class="modal-header">
            <h5 class="modal-title">Confirm Job Cancelation</h5>
        </div>
        <div class="modal-body">
            <div class="update">
                <p>Are you sure you want to cancel this job?</p>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dark" (click)="close()">CLOSE</button>
            <button type="button" class="btn btn-dark" (click)="cancelJob()" [disabled]="cancelJobLoading">YES
                <span *ngIf="cancelJobLoading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            </button>
        </div>
    </ng-template>
</div>