<div class="container">
    <div class="d-flex justify-content-center" *ngIf="isLoading">
        <app-loading></app-loading>
    </div>
    <div *ngIf="!transactionsAvailable && !isLoading">
        <h2>UH OH!</h2>
        <p>You have not made any payments yet.</p>
    </div>
    <div class="row payments" *ngIf="transactionsAvailable && !isLoading">
        <div class="col-md-4 total">
            <h3 *ngIf="role == 'client'">Total Payments</h3>
            <h3 *ngIf="role == 'freelancer'">Total Earnings</h3>
            <p>{{(totalPayments).toFixed(2)}} USD</p>
        </div>
        <div class="col-md-8">
            <div *ngIf="transactionsAvailable && !isLoading">
                <div class="card" *ngFor="let transaction of transactions | paginate : paymentsPagingConfig; let i = index">
                    <div class="card-body">
                        <h5 class="card-title">{{transaction.product}}</h5>
                        <div class="row">
                            <div class="col-md-4">
                                <p class="card-text"><strong>Date: </strong>{{transaction.created_on | date: 'mediumDate'}}</p>
                            </div>
                            <div class="col-md-4" *ngIf="role == 'client'">
                                <p class="card-text"><strong>Amount: </strong>{{(transaction.amount.toFixed(2))}} USD</p>
                            </div>
                            <div class="col-md-4" *ngIf="role == 'freelancer'">
                                <p class="card-text"><strong>Amount: </strong>{{(transaction.freelancer_amount).toFixed(2)}} USD</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center paginator">
                    <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onPaymentsDataChange($event)">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>