import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { Job } from '../../../shared/Interfaces/Job';
import { NotificationService } from '../../../core/services/notification.service';
import { Applicant } from '../../../shared/Interfaces/Applicant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, switchMap } from 'rxjs';
import { StripeFactoryService, StripeInstance } from "ngx-stripe";
import { PagingConfig } from '../../../shared/Interfaces/PagingConfig';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrl: './job-details.component.scss'
})
export class JobDetailsComponent implements OnInit, PagingConfig {
  /**
   * The job whose details are being displayed
   */
  job: Job;
  /**
   * Form for providing feedback
   */
  feedbackForm: FormGroup;
  /**
   * Stores the ID of the selected submission
   */
  submissionId: string;
  /**
   * Stores the state of the form for form validation and displaying validation errors
   */
  submitted = false;
  /**
   * Stores the ID received from the history state
   */
  ID: string;
  /**
   * Displays spinner when waiting for the job to be fetched from the backend
   */
  loading: boolean;
  /**
   * Displays spinner when waiting for the selected freelancer to be approved
   */
  approveLoading: boolean;
  /**
   * Displays spinner when waiting for the selected submission to be approved
   */
  approveSubmissionLoading: boolean;
  /**
   * Displays spinner when waiting for the selected submission to be rejected
   */
  rejectSubmissionLoading: boolean;
  /**
   * Stores the current page of the paginator
   */
  currentPage: number = 1;
  /**
   * Stores the count of the paginator
   */
  totalItems: number = 0;
  /**
   * Stores the total items displayed per page
   */
  itemsPerPage: number = 4;
  /**
   * Indicates whether a freelancer has been selected
   */
  freelancerSelected: boolean = false;
  /**
   * Displays spinner when waiting for the payment to be made
   */
  paymentLoading: boolean;
  milestoneId: string = '';
  /**
   * Used to display spinner when downloading a file
   */
  downloadingFileMap: { [key: string]: boolean } = {};

  /**
   * Modal for approval of work submission
   */
  @ViewChild('approveSubmissionModal', { static: true }) approveSubmissionModal: TemplateRef<any>;
  /**
   * Modal for rejection of work submission
   */
  @ViewChild('rejectSubmissionModal', { static: true }) rejectSubmissionModal: TemplateRef<any>;
  /**
   * Modal for confirmation of initial payment
   */
  @ViewChild('initialPaymentConfirmationModal', { static: true }) initialPaymentConfirmationModal: TemplateRef<any>;
  /**
   * Modal for confirmation of payment
   */
  @ViewChild('paymentConfirmationModal', { static: true }) paymentConfirmationModal: TemplateRef<any>;
  /**
   * Modal for confirmation of work approval
   */
  @ViewChild('approveWorkModal', { static: true }) approveWorkModal: TemplateRef<any>;

  public stripe!: StripeInstance;
  private subscriptions: Subscription;

  submissionsPagingConfig: PagingConfig = {} as PagingConfig;

  /**
   * Injects dependencies into the component
   */
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private notify: NotificationService,
    private modal: NgbModal,
    private stripeFactory: StripeFactoryService,
    private auth: AuthenticationService,
    private formBuilder: FormBuilder
  ) { 
    this.subscriptions = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Retrieves the ID of the job from the state variable
   */
  ngOnInit(): void {
    this.ID = this.route.snapshot.url[1].path;
    this.fetchJob();
    this.feedbackForm = this.formBuilder.group({
      feedback: ['', Validators.required],
    });
    // dynamically load the stripe script
    this.stripe = this.stripeFactory.create(environment.stripePublicKey);
    this.submissionsPagingConfig = {
      itemsPerPage: 3,
      currentPage: 1,
      totalItems: 0
    }
  }

  /**
   * Getter for all the feedbackForm form controls
   */
  get f(): FormGroup['controls'] {
    return this.feedbackForm.controls;
  }

  /**
   * Fetches the job details from an API endpoint
   */
  fetchJob(): void {
    this.loading = true;
    if (this.ID) {
      this.api.get('/jobs/client/' + this.ID).subscribe({
        next: res => {
          if (res.status_code === 200) {
            this.job = res.detail;
            this.submissionsPagingConfig.totalItems = this.job.submissions.length;
          } else {
            this.notify.showError(res.detail);
            this.ID = '';
          }
          this.loading = false;
        },
        error: (e) => {
          this.loading = false;
          console.error(e);
        }
      });
    } else {
      this.notify.showError('Kindly provide a job ID');
      this.loading = false;
    }
  }

  hasPendingSubmissions(): boolean {
    return this.job.submissions?.some(submission => submission.status === 'pending');
  }

  /**
   * Approves the selected applicant
   */
  approveApplicant(job_id: string, freelancer_id: string): void {
    this.approveLoading = true;

    const approval = {
      job_id: job_id,
      freelancer_id: freelancer_id
    }

    this.api.put('/jobs/approve-applicant', JSON.parse(JSON.stringify(approval))).subscribe(
      res => {
        if (res.status_code === 200) {
          this.notify.showSuccess(res.detail);
          this.fetchJob();
        } else {
          this.notify.showError(res.detail);
        }
        this.approveLoading = false;
      }
    );
  }

  /**
   * Detects if the data in the submissions has changed
   * @param page - The page that has been selected
   */
  onSubmissionsDataChange(event: any): void{
    this.submissionsPagingConfig.currentPage = event;
    this.fetchJob();
  }

  sendMessage(freelancer: Applicant) {
    this.router.navigate(['messages'], {
      state: { 
        _id: freelancer._id,
        firstname: freelancer.firstname,
        lastname: freelancer.lastname 
      }
    });
  }

  displayApproveSubmissionModal(submissionId: string): void {
    this.submissionId= submissionId;
    this.modal.open(this.approveSubmissionModal, { size: 'md', centered: true });
  }

  displayRejectSubmissionModal(submissionId: string): void {
    this.submissionId= submissionId;
    this.modal.open(this.approveSubmissionModal, { size: 'md', centered: true });
  }

  displayApproveWorkModal(): void {
    this.modal.open(this.approveWorkModal, { size: 'md', centered: true });
  }

  displayInitialPaymentConfirmationModal(): void {
    this.modal.open(this.initialPaymentConfirmationModal, { size: 'md', centered: true });
  }

  displayPaymentConfirmationModal(milestoneId?: string): void {
    this.milestoneId = '';
    if (milestoneId) {
      this.milestoneId = milestoneId;
    }
    this.modal.open(this.paymentConfirmationModal, { size: 'md', centered: true });
  }
  

  checkout(amount: number) {
    this.paymentLoading = true;

    const token = this.auth.decrypt(localStorage.getItem('token'));
  
    const payment = {
      amount: Math.round(amount * 100),
      client: this.job.client_id,
      freelancer: this.job.freelancer._id,
      job: this.job._id,
      product: this.job.title,
      milestone: this.milestoneId,
      token: token
    };
  
    const endpoint = '/jobs/create-checkout-session';
    
    const checkout: Subscription = this.api.post(endpoint, JSON.parse(JSON.stringify(payment)))
      .pipe(
        switchMap((response: any) => {
          const session: IStripeSession = response as IStripeSession;
          this.paymentLoading = false;
          return this.stripe.redirectToCheckout({ sessionId: session.id });
        })
      )
      .subscribe(result => {
        this.paymentLoading = false;
        // If `redirectToCheckout` fails due to a browser or network error
        if (result.error) {
          this.paymentLoading = false;
        }
      });
  
    this.subscriptions.add(checkout);
  }
  

  /**
   * Approves the work done by the selected applicant
   */
  approveWork(): void {
    this.approveLoading = true;

    const approval = {
      job_id: this.job._id,
    }

    this.api.put('/jobs/approve-work', JSON.parse(JSON.stringify(approval))).subscribe(
      res => {
        if (res.status_code === 200) {
          this.notify.showSuccess(res.detail);
          this.fetchJob();
        } else {
          this.notify.showError(res.detail);
        }
        this.approveLoading = false;
        this.modal.dismissAll(this.approveWorkModal);
      }
    );
  }


  /**
   * Approves the submission selected by the client
   */
  approveSubmission(): void {
    this.submitted = true;

    if (this.feedbackForm.invalid) {
      return;
    }

    this.approveSubmissionLoading = true;

    const approval = {
      job_id: this.job._id,
      submission_id: this.submissionId,
      feedback: this.feedbackForm.get('feedback')?.value
    }

    this.api.put('/jobs/approve-submission', JSON.parse(JSON.stringify(approval))).subscribe(
      res => {
        if (res.status_code === 200) {
          this.notify.showSuccess(res.detail);
          this.submitted = false;
          this.feedbackForm.reset();
          this.fetchJob();
        } else {
          this.notify.showError(res.detail);
          this.submitted = false;
        }
        this.approveSubmissionLoading = false;
        this.modal.dismissAll(this.approveSubmissionModal);
      }
    );
  }

  /**
   * Rejects the submission selected by the client
   */
  rejectSubmission(): void {
    this.submitted = true;

    if (this.feedbackForm.invalid) {
      return;
    }

    this.rejectSubmissionLoading = true;

    const approval = {
      job_id: this.job._id,
      submission_id: this.submissionId,
      feedback: this.feedbackForm.get('feedback')?.value
    }

    this.api.put('/jobs/reject-submission', JSON.parse(JSON.stringify(approval))).subscribe(
      res => {
        if (res.status_code === 200) {
          this.notify.showSuccess(res.detail);
          this.submitted = false;
          this.feedbackForm.reset();
          this.fetchJob();
        } else {
          this.notify.showError(res.detail);
          this.submitted = false;
        }
        this.rejectSubmissionLoading = false;
        this.modal.dismissAll(this.rejectSubmissionModal);
      }
    );
  }

  downloadFile(file_name: string): void {
    this.downloadingFileMap[file_name] = true;
    this.api.getFile(`/jobs/download-file/${file_name}`).subscribe(
      blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = file_name;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.downloadingFileMap[file_name] = false;
      }
    )
  }

}
interface IStripeSession {
  id: string;
}